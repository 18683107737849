<template></template>

<script>
import { clearLocalStorage } from "../helper/localStorage.js";
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions("customer", ["loginAsCustomer"]),
  },
  created() {
    clearLocalStorage();

    const config = {
      headers: { Authorization: "Bearer " + this.$route.query.token },
    };

    const payload = {
      customerId: this.$route.query.customerId,
      config: config,
    };

    this.loginAsCustomer(payload)
      .then((result) => {
        this.$router.replace({
          name:"paymentRequestDetail",
          params: {prId: result.data.data.prId}
        })
      })
      .catch((ex) => {
        this.$vs.notify({
          title: "Login",
          text: ex.message ? ex.message : "Failed to login",
          color: "danger",
          iconPack: "feather",
          position: "top-right",
          icon: "icon-times",
        });
        this.$router.push({ name: "page-error-404" });
      });
  },
};
</script>
